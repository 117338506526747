import cx from "classnames"
import React, { useEffect, useState } from "react"
import { HERO_SOCIALS } from "../../fixtures/SocialsData"
import { useGuestApi } from "../../hooks/useApi"
import NowPlaying from "../NowPlaying/NowPlaying"
import Icon from "./../Icon/Icon"

import "./Hero.scss"

const Hero = ({className}) => {
  const { getMainHeader } = useGuestApi()
  const [header, setHeader] = useState(null);

  useEffect(() => {
    async function fetchMainHeader() {
      try {
        await getMainHeader().then(header => {
          setHeader(header ?? null);
        })
      } catch(e) {
        setHeader(null)
      }
    }
    fetchMainHeader()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchMainHeader() {
      try {
        await getMainHeader().then(header => {
          setHeader(header ?? null);
        })
      } catch(e) {
        setHeader(null)
      }
    }
    const interval = setInterval(() => {
      fetchMainHeader()
    }, 60000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className={cx("hero", className)}>
      <div className="hero__inner">
        <div className="hero__visual">
          <img src={header?.primaryImage} alt={header?.subtitle} className="hero__visual-el" />
        </div>
        <div className="hero__content">
          {header?.titleImage &&
            <h2 className="hero__title">
              <span className="u-visually-hidden">{header?.title}</span>
              <img src={header?.titleImage} alt={header?.title} className="hero__title-el" />
            </h2>
          }
          <div className="hero__radio-player">
            <NowPlaying />
          </div>
          <ul className="hero__socials">
            {HERO_SOCIALS.map(({ url, icon, title }) => (
              <li className="hero__social" key={title}>
                <a
                  className="hero__social__link"
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  title={title}
                >
                  <Icon className="hero__social__icon" name={icon} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Hero
